import React from 'react';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as PropTypes from 'prop-types';
import { Grid, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import BigContentHeaderLayout from '$landing-page/layouts/big-content-header-layout';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

const query = graphql`
    query usecasesIndexPage {
        snapImage: file(relativePath: { eq: "page-usecases-snap.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
        foodborneImage: file(relativePath: { eq: "page-usecases-foodborne.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
        schizophreniaImage: file(relativePath: { eq: "page-usecases-schizophrenia.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
        fadImage: file(relativePath: { eq: "page-usecases-fad.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
        walkingImage: file(relativePath: { eq: "page-usecases-walking.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
        buildingImage: file(relativePath: { eq: "page-usecases-building.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
        smartImage: file(relativePath: { eq: "page-usecases-smart.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
        wellbeingImage: file(relativePath: { eq: "page-usecases-wellbeing.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED, height: 128, quality: 100, placeholder: NONE)
            }
        }
    }
`;

const UsecasesIndexPage = (props) => {
    const { location } = props;

    const classes = useStyles();
    const theme = useTheme();
    const queryData = useStaticQuery(query);
    const isScreenSizeMd = useMediaQuery(theme.breakpoints.up('md'));

    const usecases = [
        {
            url: '/usecases/project-snap-smoking-are-tobacco-companies-sweet-talking-our-kids',
            title: 'Project SNAP',
            image: queryData.snapImage,
        },
        {
            url: '/usecases/exploring-new-technologies-for-foodborne-disease',
            title: 'Exploring New Technologies for Foodborne Disease',
            image: queryData.foodborneImage,
        },
        {
            url: '/usecases/social-isolation-and-schizophrenia',
            title: 'Social Isolation and Schizophrenia',
            image: queryData.schizophreniaImage,
        },
        {
            url: '/usecases/opportunistic-analysis-of-a-mobile-fad',
            title: 'Opportunistic Analysis of a Mobile Fad',
            image: queryData.fadImage,
        },
        {
            url: '/usecases/walking-riding-and-the-weather',
            title: 'Walking, Riding, and the Weather',
            image: queryData.walkingImage,
        },
        {
            url: '/usecases/changing-buildings-changing-behaviour',
            title: 'Changing Buildings, Changing behavior',
            image: queryData.buildingImage,
        },
        {
            url: '/usecases/smart-engage-citizens-to-overcome-the-physical-inactivity',
            title: 'SMART',
            image: queryData.smartImage,
        },
        {
            url: '/usecases/wellbeing-of-tertiary-students',
            title: 'Student Wellbeing',
            image: queryData.wellbeingImage,
        },
    ];

    return (
        <BigContentHeaderLayout
            pathname={location.pathname}
            title="Use Cases"
            description="Avicenna Research offers a flexible platform which can be configured for many different scientific studies. The following use cases demonestrate how Avicenna Research can help different study designs."
        >
            <Grid container spacing={5}>
                {usecases.map((usecase) => (
                    <Grid item xs={12} md={6} key={usecase.title}>
                        <Grid
                            container
                            alignContent="center"
                            alignItems="center"
                            justify="center"
                            wrap="nowrap"
                            className={classes.usecaseWrapper}
                            component={Link}
                            to={usecase.url}
                        >
                            <Grid item>
                                <GatsbyImage
                                    image={usecase.image.childImageSharp.gatsbyImageData}
                                    className={classes.usecaseImage}
                                    alt={usecase.title}
                                />
                            </Grid>
                            <Grid item xs={isScreenSizeMd ? false : 12} className={classes.usecaseTitleWrapper}>
                                <Typography variant="h6" component="h2" color="textSecondary" className={classes.usecaseTitle}>
                                    {usecase.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
                <Grid item xs={12} md={6}>
                    <Grid
                        container
                        alignContent="center"
                        alignItems="center"
                        justify="center"
                        wrap="nowrap"
                        className={classes.usecaseWrapper}
                        component="a"
                        href="https://learn.avicennaresearch.com/category/use-cases"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Grid item>
                            <Paper
                                component={Grid}
                                container
                                alignItems="center"
                                justify="center"
                                className={clsx(classes.seeMore, classes.usecaseImage)}
                            >
                                <FontAwesomeIcon icon={['fal', 'ellipsis-h-alt']} />
                            </Paper>
                        </Grid>
                        <Grid item xs={isScreenSizeMd ? false : 12} className={classes.usecaseTitleWrapper}>
                            <Typography variant="h6" component="h2" color="textSecondary" className={classes.usecaseTitle}>
                                See more
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </BigContentHeaderLayout>
    );
};

UsecasesIndexPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default UsecasesIndexPage;
